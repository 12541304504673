import { createSlice } from '@reduxjs/toolkit';

const scanDataReducer = createSlice({
    name: 'scanData',
    initialState: {
        metaData: {
            id: '',
            domainName: '',
            startedAt: '',
            status: '',
        },
        scanData: [],
        scanMetrics: {
            total_scans: 0,
            total_domains: 0,
            mean_time: 0
        },
        severityCounts: {
            total: 0,
            critical: 0,
            high: 0,
            medium: 0,
            low: 0,
            info: 1,
        },
        totalSeverityCounts: {
            critical: 0,
            high: 0,
            medium: 0,
            low: 0,
            info: 0,
        },
        totalVulnCount: 0,
        page: 1,
        totalPages: 0,
        scanToolData: []
    },
    reducers: {
        setScanToolData: (state, action) => {
            state.scanToolData = action.payload;
        },
        setMetaData: (state, action) => {
            state.metaData = action.payload;
        },
        setScanMetrics: (state, action) => {
            state.scanMetrics = action.payload;
        },
        setScanData: (state, action) => {
            state.scanData = action.payload;
        },
        setSeverityCounts: (state, action) => {
            state.severityCounts = action.payload;
        },
        setTotalServerityCounts: (state, action) => {
            state.totalSeverityCounts = action.payload;
        },
        setTotalVulnCount: (state, action) => {
            state.totalVulnCount = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setTotalPages: (state, action) => {
            state.totalPages = action.payload;
        },
    }
});

export const { 
    setScanToolData,
    setMetaData, 
    setScanProgress, 
    setScanData, 
    setSeverityCounts,
    setTotalServerityCounts,
    setTotalVulnCount, 
    setPage, 
    setTotalPages,
    setScanMetrics
} = scanDataReducer.actions;
export default scanDataReducer.reducer;