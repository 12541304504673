import {createSlice} from '@reduxjs/toolkit';

const authReducer = createSlice({
    name: 'auth',
    initialState: {
        loggedIn: false,
        email: '',
        uid: '',
        isVerified: false,

    },
    reducers: {
        setAuth: (state, action) => {
            const { loggedIn, email } = action.payload
            state.loggedIn = loggedIn
            state.email = email
        },
        setIsVerified: (state, action) => {
            const { isVerified } = action.payload
            state.isVerified = isVerified
        },
        setUID: (state, action) => {
            const { uid } = action.payload
            state.uid = uid
        },
        setName(state, action) {  // Add setName reducer
            state.full_name = action.payload.full_name;
          },

        clearUserData: (state) => {
        // Reset state to initial values or any specific values you want on logout
        state.loggedIn = false;
        state.email = '';
        state.uid = '';
        state.isVerified = false;
        state.full_name = '';
        }
    }
});

export const { setAuth, setIsVerified, setUID, setName, clearUserData } = authReducer.actions;
export default authReducer.reducer;