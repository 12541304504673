import { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { axiosInstance } from "../api/axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { otpValidationSchema } from "../helpers/otpValidationSchema";
import {
  setLoading,
  setFeedback,
  setError,
  setMessage,
  setOTPSent,
  setVerify,
} from "../redux/reducers/emailVerificationReducer";
import { ClipLoader } from "react-spinners";
import { email_verification, otp_send } from "../api/auth";
import { Button } from "./ui/button";
import { Card } from "./ui/card";

const OTPSend = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const abortControllerRef = useRef(null);
  const isLoading = useSelector((state) => state.email_verification.loading);
  const isFeedback = useSelector((state) => state.email_verification.feedback);
  const isOTPSent = useSelector((state) => state.email_verification.otpSent);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [counter]);

  const handleOTPSend = async () => {
    dispatch(setLoading({ loading: true }));
    dispatch(setFeedback({ feedback: false }));

    try {
      const response = await axiosInstance.get(otp_send);
      dispatch(setLoading({ loading: false }));
      dispatch(setVerify({ verify: true }));
      dispatch(setFeedback({ feedback: true }));
      dispatch(setMessage({ message: response.data.message }));
      dispatch(setOTPSent({ otp: true }));
      setCounter(60);
    } catch (err) {
      dispatch(setLoading({ loading: false }));
      dispatch(setFeedback({ feedback: true }));
      dispatch(setMessage({ message: err.response.data.error }));
    }
  };

  return (
    <Button
      className="w-full mx-auto p-4"
      disabled={counter > 0 || isOTPSent}
      onClick={() => {
        dispatch(setOTPSent({ otpSent: true }));
        handleOTPSend();
      }}
    >
      {isLoading ? (
        <ClipLoader size={21} color={"#fff"} />
      ) : counter > 0 ? (
        `Resend OTP in ${counter}s`
      ) : (
        "Send OTP"
      )}
    </Button>
  );
};

export default OTPSend;
