import * as Yup from 'yup';

const validateNoShellCommands = (value) => {
    const shellCommandPattern = /[`;&|${}<>]/g;
    if (shellCommandPattern.test(value)) {
        return "Invalid Input.";
    }
};

export const otpValidationSchema = Yup.object().shape({
    code: Yup.string()
        .min(8, 'Invalid Code')
        .max(8, 'Invalid Code')
        .required('Code is required')
        .test('no-shell-commands', 'Invalid characters in Code',
            value => !validateNoShellCommands(value)),
});
