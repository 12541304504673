import { Search } from "lucide-react";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Label } from "../components/ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { useState, useEffect } from "react";
import { axiosInstance } from "../api/axios";
import { Card, CardContent, Typography, Grid,Box,Drawer, IconButton,Divider,Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import {
  Bar,
  BarChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
  Legend,
  LineChart,
  Line,
  CartesianGrid,
  AreaChart, Area,
} from "recharts";

const data = [
  { name: 'Page A', uv: 100, pv: 2400 },
  { name: 'Page B', uv: 3000, pv: 1398 },
  { name: 'Page C', uv: 2000, pv: 9800 },
  { name: 'Page D', uv: 2780, pv: 3908 },
  { name: 'Page E', uv: 1890, pv: 4800 },
  { name: 'Page F', uv: 2390, pv: 3800 },
  { name: 'Page G', uv: 3490, pv: 4300 },
  
];

export const ScanComponent = () => {
  const [searchTxt, setSearchTxt] = useState("");
  const [tableData, setTableData] = useState([]);
  const [totalScans, setTotalScans] = useState(0);
  const [completedScans, setCompletedScans] = useState(0);
  const [inProgressScans, setInProgressScans] = useState(0);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);

  

  const getScansData = async () => {
      const { data } = await axiosInstance.get(`/scans/scan`);
      const rows = data?.data?.map((row) => ({
        id: row?.id,
        domain: row?.domain_name,
        created: `${new Date(row?.created_at)?.toDateString()} ${new Date(row?.created_at)?.toLocaleTimeString()}`,
        status: row?.status
      }));
      setTableData(rows);
      calculateTotals(rows);
  };

  const triggerScan = async () => {
    const { data } = await axiosInstance.post(`/scans/scan`, {
        domain_name: searchTxt,
    });
    getScansData()
  }

  useEffect(() => {
    getScansData();
  }, [])

  const handleRowClick = async (record) => {
    try {
      const { data } = await axiosInstance.post(`/scans/vuln-data`, {
        scan_id: record.id,
      });
      
  
      const details = data?.data?.filter(row => row.scan_id === record.id)
        .map(row => {
          const rowtool = row;
          const toolOutput = row?.data?.tool_data?.tool_output;
          const toolName = row?.data?.tool_data?.tool_name;

          let asns = [];
          let urls = [];
          let ips = [];
          let emails = [];
          let hosts = [];
          let protocol = '-';
          let endpoint = '-';
          let family = '';
          let ports = [];
          let interestingUrls = [];
          
          let subdomains= [];
          let domainName = '';
          let registryDomainId = '';
          let registrar = '';
          let registrarUrl = '';
          let registrarWhoisServer = '';
          let creationDate = '';
          let domainStatus = '';
          let nameServer = '';
          let dnssec = '';
          let registrarAbuseContactEmail = '';
          let registrarAbuseContactPhone = '';
          let detection = '';
          let product = "";
          let title = "";
          let severity = "";
          let related_info = ""
              

          
          switch (toolName) {
            
           
            
            case 'Nuclei':
              detection = toolOutput?.title ;
              title = toolOutput?.title
              severity = toolOutput?.severity
              protocol = toolOutput?.protocol
              endpoint = toolOutput?.endpoint
              related_info = toolOutput?.related_info
              
              
              break;



            case 'theharvester':
              detection = rowtool?.title;
              asns = toolOutput?.ASNS ?? [];
              urls = toolOutput?.["Interesting Urls"] ?? [];
              ips = toolOutput?.IPs ?? [];
              emails = toolOutput?.Emails ?? [];
              hosts = toolOutput?.Hosts ?? [];
              
              break;
            
            case 'Nmap':
              detection = 'Open ports found';
              ports = toolOutput?.port_details?.map(port => ({
                portid: port.portid ?? '-',
                state: port.state ?? '-',
                service: port.service ?? '-',
                tunnel: port.tunnel ?? '-',
                version: port.version ?? '-',
                product: port.product ?? '-',
                extraInfo: port.extra_info ?? '-',
                osType: port.os_type ?? '-',
                script: port.script ?? []
              })) ?? [];
              break;

            case 'whois':
              detection = 'DNS records found'; 
              domainName = toolOutput?.domain_name ?? '';
              registryDomainId = toolOutput?.registry_domain_id ?? '';
              registrar = toolOutput?.registrar ?? '';
              registrarUrl = toolOutput?.registrar_url ?? '';
              registrarWhoisServer = toolOutput?.registrar_whois_server ?? '';
              creationDate = toolOutput?.creation_date ?? '';
              domainStatus = toolOutput?.domain_status ?? '';
              nameServer = toolOutput?.name_server ?? '';
              dnssec = toolOutput?.dnssec ?? '';
              registrarAbuseContactEmail = toolOutput?.registrar_abuse_contact_email ?? '';
              registrarAbuseContactPhone = toolOutput?.registrar_abuse_contact_phone ?? '';
              break;

            case 'multi':
              detection = 'Subdomains found';
              subdomains = toolOutput?.subdomains ?? [];
              
              break;
            

            default:
              console.warn(`Unknown tool: ${toolName}`);
          }

          return {
            toolOutput,
            detection,
            toolname: toolName,
            severity: row.severity,
            protocol,
            endpoint,
            family,
            asns,
            urls,
            ips,
            emails,
            hosts,
            ports,
            domainName,
            registryDomainId,
            registrar,
            registrarUrl,
            registrarWhoisServer,
            creationDate,
            domainStatus,
            nameServer,
            dnssec,
            registrarAbuseContactEmail,
            registrarAbuseContactPhone,
            subdomains,
            product
            
            

          };
          
          
        });
        

  
      const severityCategories = {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
        info: 0,
      };
  
      details.forEach(detail => {
        const severity = detail.severity.toLowerCase();
         
        if (severityCategories.hasOwnProperty(severity)) {
          severityCategories[severity]++;
        }
      });
     
  
      setSelectedRecord({
        ...record,
        details,
        severityCounts: severityCategories
      });
    } catch (error) {
      
    }
  };
  
  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedDetail(null);
  };
  const handleDetailRowClick = (detail) => {
   
    setSelectedDetail(detail);
    setDrawerOpen(true);
  };


  const calculateTotals = (data) => {
    const total = data.length;
    const completed = data.filter((row) => row.status === 'Completed').length;
    const inProgress = data.filter((row) => row.status === 'in-progress').length;

    setTotalScans(total);
    setCompletedScans(completed);
    setInProgressScans(inProgress);
  };
  const severityColors = {
    critical: "#f17e92",
    high: "#fbd5da",
    medium: "#bedcfe",
    low: "#90c7fe",
    info: "#3b86f7",
   
  };

  const getSeverityChartData = (severityCounts) => {
    return Object.entries(severityCounts).map(([severity, count]) => ({
      label: severity.charAt(0).toUpperCase() + severity.slice(1),
      value: count,
      color: severityColors[severity] ,
    }));
  };

  const handleBackClick = () => {
    setSelectedRecord(null);
  };


  if (selectedRecord) {
    return (
      
      
      <Box>
        <ResponsiveContainer width="100%" height={350} >
          
          <Button onClick={handleBackClick}>Back</Button>


          <Box>
            <div className="mt-10">
              <div className="border text-xl shadow-md flex justify-between items-center mb-4 px-6 py-2 rounded-md">
                <div>Domain: {selectedRecord.domain}</div>
                <div className="text-sm text-gray-600">Created: {selectedRecord.created}</div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-12">

              <Card variant="outlined" >
                <CardContent>
                  <Typography variant="h6" component="div" sx={{ mb: 2, fontWeight: 'bold' }}>
                    Scan Details
                  </Typography>
                  <Divider sx={{ mb: 2 }} />
                  <Box>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      <strong>ID:</strong> {selectedRecord.id}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      <strong>Domain:</strong> {selectedRecord.domain}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      <strong>Created At:</strong> {selectedRecord.created}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      <strong>Status:</strong> {selectedRecord.status}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>

              <Card variant="outlined">
                <ResponsiveContainer width="100%" height={350} >

                  <Box mt={4}>
                    <ResponsiveContainer width="100%" height={350}>
                      <PieChart>
                        <Pie
                          data={getSeverityChartData(selectedRecord.severityCounts)}
                          dataKey="value"
                          isAnimationActive={true}
                          innerRadius={80}
                          outerRadius={120}
                        >
                          {getSeverityChartData(selectedRecord.severityCounts).map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                        </Pie>
                        <Tooltip
                          formatter={(value, name, props) => [`${props.payload.label}`]}
                        />
                        <Legend
                          layout="horizontal"
                          align="center"
                          verticalAlign="bottom"
                          formatter={(value) => (
                            <span style={{ color: 'gray', fontSize: '12px', margin: '0 10px' }}>
                              {value}
                            </span>
                          )}
                          payload={
                            getSeverityChartData(selectedRecord.severityCounts).map((item) => ({
                              id: item.label,
                              type: "square",
                              value: `${item.label}`,
                              color: item.color,
                            }))
                          }
                          wrapperStyle={{
                            display: 'flex',
                            justifyContent: 'center',
                            color: 'black'
                          }}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </Box>


                </ResponsiveContainer>
              </Card>


            </div>
            <ResponsiveContainer width="100%" height={350}>
              <Box mt={4}>
                <Typography variant="h6">Findings</Typography>
                <Table className="mt-8">
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-2/3 font-bold">DETECTION</TableHead>
                      <TableHead className="font-bold">SEVERITY</TableHead>
                      {/*<TableHead className="font-bold">FAMILY</TableHead>*/}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {selectedRecord.details
                      .filter((detail) => detail.detection)
                      .map((detail, ind) => (
                        <TableRow key={ind} className="cursor-pointer" onClick={() => handleDetailRowClick(detail)}>
                          <TableCell className="truncate max-w-xs">{detail.detection}</TableCell>
                          <TableCell className="truncate max-w-xs">{detail.severity}</TableCell>
                          {/*<TableCell className="truncate max-w-xs">{detail.family}</TableCell>*/}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </ResponsiveContainer>

            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={handleDrawerClose}
              PaperProps={{
                style: {
                  width: '50vw', // Set the Drawer width to 30% of the viewport width
                },
              }}
            >
              <Box
                sx={{ width: '100%', padding: 2, boxSizing: 'border-box' }}
                role="presentation"
              >
                <IconButton
                  onClick={handleDrawerClose}
                  sx={{ position: 'absolute', top: 10, right: 10 }}
                >
                  <CloseIcon />
                </IconButton>
                {selectedDetail && (
                  <Box >
                    <Typography variant="h6" gutterBottom>
                      {selectedDetail.detection}
                    </Typography>
                    <Box sx={{ mb: 6, mt: 5 }}>
                      <Typography variant="body1" sx={{ mb: 1 }}><strong>Detection:</strong> {selectedDetail.detection}</Typography>
                      {/* <Typography variant="body1" sx={{ mb: 1 }}><strong>Tool Name:</strong> {selectedDetail.toolname}</Typography> */}
                      <Typography variant="body1" sx={{ mb: 1 }}><strong>Severity:</strong> {selectedDetail.severity}</Typography>
                      
                    </Box>


                    {selectedDetail.toolname === 'theharvester' && (
                      <>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>ASNS</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ maxHeight: '200px', overflowY: 'auto' }}>
                          <List>
                            {selectedDetail.asns.length > 0 ? (
                              selectedDetail.asns.map((asn, index) => (
                                <ListItem key={index}>
                                  <ListItemText primary={asn} />
                                </ListItem>
                              ))
                            ) : (
                              <Typography variant="body2">No ASNs available</Typography>
                            )}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>URLs</Typography>
                          </AccordionSummary>
                          <AccordionDetails sx={{ maxHeight: '200px', overflowY: 'auto' }}>
                            <List>
                              {selectedDetail.urls.length > 0 ? (
                                selectedDetail.urls.map((url, index) => (
                                  <ListItem key={index}>
                                    <ListItemText primary={url} />
                                  </ListItem>
                                ))
                              ) : (
                                <Typography variant="body2">No URLs available</Typography>
                              )}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Emails</Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ maxHeight: '200px', overflowY: 'auto' }}>
                        <List>
                          {selectedDetail.emails.length > 0 ? (
                            selectedDetail.emails.map((email, index) => (
                              <ListItem key={index}>
                                <ListItemText primary={email} />
                              </ListItem>
                            ))
                          ) : (
                            <Typography variant="body2">No emails available</Typography>
                          )}
                        </List>
                      </AccordionDetails>
                         </Accordion>
                        
                        </>

                        


                    )}

                    

                    {selectedDetail.toolname === 'Nmap' && (
                      <><><Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>Ports</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ maxHeight: '200px', overflowY: 'auto' }}>
                          <List>
                            {selectedDetail.ports.length > 0 ? (
                              selectedDetail.ports.map((port, index) => (
                                <ListItem key={index}>
                                  <ListItemText primary={`Port: ${port.portid} - ${port.service}- ${port.product}`} />
                                </ListItem>
                              ))
                            ) : (
                              <Typography variant="body2">No ports available</Typography>
                            )}
                          </List>
                        </AccordionDetails>
                      </Accordion></><></></>

                      
                    )}


                    {selectedDetail.subdomains.length > 0 && (
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>Subdomains</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <List>
                            {selectedDetail.subdomains.map((subdomain, index) => (
                              <ListItem key={index}>
                                <ListItemText primary={subdomain} />
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    )}


                    

                    {selectedDetail.toolname === 'whois' && (
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>DNS Information</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ maxHeight: '200px', overflowY: 'auto' }}>
                          <Box>
                            <Typography variant="body1"><strong>Domain Name:</strong> {selectedDetail.domainName}</Typography>
                            <Typography variant="body1"><strong>Registrar:</strong> {selectedDetail.registrar}</Typography>
                            <Typography variant="body1"><strong>Registry Domain URL:</strong> {selectedDetail.registrarUrl}</Typography>
                            <Typography variant="body1"><strong>Name server:</strong> {selectedDetail.nameServer}</Typography>
                            <Typography variant="body1"><strong>Registry Domain ID:</strong> {selectedDetail.registryDomainId}</Typography>
                            <Typography variant="body1"><strong>Registrar whois server:</strong> {selectedDetail.registrarWhoisServer}</Typography>
                            <Typography variant="body1"><strong>Name server:</strong> {selectedDetail.nameServer}</Typography>
                            <Typography variant="body1"><strong>DNSSEC:</strong> {selectedDetail.dnssec}</Typography>
                            <Typography variant="body1"><strong>Registrar abuse contact email:</strong> {selectedDetail.registrarAbuseContactEmail}</Typography>
                            <Typography variant="body1"><strong>Registrar Abuse Contact Phone:</strong> {selectedDetail.registrarAbuseContactPhone}</Typography>
                            
                            {/* Add more WHOIS information as needed */}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    )}

                    {selectedDetail.toolname === 'Nuclei' && (
                      <><Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>Vulnerability Information</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ maxHeight: '200px', overflowY: 'auto' }}>
                          <Box>
                            <Typography variant="body1"><strong>protocol:</strong> {selectedDetail.protocol}</Typography>
                            <Typography variant="body1"><strong>Endpoint:</strong> {selectedDetail.endpoint}</Typography>
                            {selectedDetail.related_info != null ? (
                                <Typography variant="body1"><strong>related_info:</strong> {selectedDetail.related_info}</Typography>

                            ): null}
                            
                          </Box>
                        </AccordionDetails>

                      </Accordion>
                      {/*
                      <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Related Information</Typography>
                          </AccordionSummary>
                          <AccordionDetails sx={{ maxHeight: '200px', overflowY: 'auto' }}>
                            <Box>
                              
                              <Typography variant="body1"><strong>related_info:</strong> {selectedDetail.related_info}</Typography>

                            </Box>
                          </AccordionDetails>

                    </Accordion>
                    */}
                    </> 
                    )}


                    

                    
                  </Box>
                )}
              </Box>
            </Drawer>



            







            {/* <Typography variant="h6">Scan Details</Typography>
          <Typography variant="body1">ID: {selectedRecord.id}</Typography>
          <Typography variant="body1">Domain: {selectedRecord.domain}</Typography>
          <Typography variant="body1">Created At: {selectedRecord.created}</Typography>
          <Typography variant="body1">Status: {selectedRecord.status}</Typography> */}



            {/* {selectedRecord.details && (
            <Box mt={2}>
              <Typography variant="h6">Scan Details</Typography>
              {selectedRecord.details.map((detail, index) => (
                <Box key={index} mt={2}>
                  <Typography variant="body2">Detection: {detail.detection}</Typography>
                  <Typography variant="body2">Tool Name: {detail.toolname}</Typography>
                  <Typography variant="body2">Severity: {detail.severity}</Typography>
                  
                </Box>
              ))}
            </Box>
          )} */}

            {/* <Box mt={4}>
            <Typography variant="h6">Severity Counts</Typography>
            {Object.entries(selectedRecord.severityCounts || {}).map(([severity, count]) => (
              <Typography key={severity} variant="body2">
                {severity}: {count}
              </Typography>
            ))}
          </Box> */}


          </Box>
        </ResponsiveContainer>
      </Box>
    );
  }

  return (
    <Box className="">
      <Box className="font-bold text-2xl mb-4">Scan</Box>
      <Box className="py-4 mt-6">
        <Box className="flex gap-2">
          <Box className="relative flex-1 max-w-md">
            <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              type="search"
              placeholder="Enter Domain Name..."
              className="w-full p-4 rounded-lg bg-background pl-8"
              value={searchTxt}
              onChange={(e) => {
                setSearchTxt(e?.target?.value);
              }}
            />
          </Box>
          <Button
            className="min-w-24"
            onClick={() => {
              triggerScan();
            }}
          >
            Scan
          </Button>
        </Box>
      </Box>

      {/* table */}
      <Table className="mt-8">
        <TableHeader>
          <TableRow>
            <TableHead className="w-1/3">ID</TableHead>
            <TableHead>Domain Name</TableHead>
            <TableHead>Created At</TableHead>
            <TableHead>Status</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {tableData.map((data, ind) => (
            <TableRow key={ind} onClick={() => handleRowClick(data)}>
              <TableCell className="font-medium truncate w-[100px]">
                {data.id}
              </TableCell>
              <TableCell>{data.domain}</TableCell>
              <TableCell>{data.created}</TableCell>
              <TableCell>{data.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
