import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import OTPVerificationForm from "../components/OTPVerificationForm";
import OTPSend from "../components/OTPSend";
import Feedback from "../components/shared/Feedback";
import SecureNEX from "../assets/images/secureNEX.png";


const EmailVerification = () => {
  const isVerified = useSelector((state) => state.auth.isVerified);

  return (
    <div className="flex h-screen">
      <div className="flex-1 hidden lg:flex bg-zinc-900 justify-center text-white items-center">
        <div>
          <div className="p-4 bg-white rounded-xl">
            <img src={SecureNEX} alt="SecureNEX Logo" className="w-32 h-32 relative left-2" />
          </div>
          <div className="text-3xl font-semibold text-center mt-2">Secure NEX</div>
        </div>
      </div>
      <div className="flex-1 bg-white flex justify-center items-center">
        <div className="flex flex-col gap-2 w-96">
          <div className="text-2xl font-semibold text-center mb-4">Email Verification</div>
          {!isVerified ? (
            <div className="container p-5 shadow">
              <OTPVerificationForm />
              <OTPSend />
              <Link to="/dashboard" className="text-black text-center">
                <span className="text-black">Go To Home</span>
              </Link>
            </div>
          ) : (
            <div className="container p-5 shadow">
              <Feedback message="Email was verified!" />
              <div className="mt-4 w-100">
                <h6 className="text-center pb-2 w-100">Login to the Account</h6>
                <Link to="/login" className="btn btn-secondary btn-outline-primary text-light w-100">
                  <span className="text-light">Login</span>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
