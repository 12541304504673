import React, { useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { axiosInstance } from "../api/axios";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
// import registrationValidationSchema from '../helpers/registrationValidationSchema';
import { registration } from "../api/auth";
import {
  setLoading,
  setFeedback,
  setError,
  setMessage,
} from "../redux/reducers/registrationReducer";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { Button } from "./ui/button";

const RegistrationForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const abortControllerRef = useRef(null);
  const isLoading = useSelector((state) => state.registration.loading);

  const validationSchema = yup.object({
    firstName: yup
      .string("Enter your First Name")
      .required("First Name is required"),
    lastName: yup
      .string("Enter your Last Name")
      .required("Last Name is required"),
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });

  const handleRegistration = async (values, { setSubmitting, resetForm }) => {
    abortControllerRef.current = new AbortController();
    dispatch(setLoading({ loading: true }));

    try {
      let user_data = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        password: values.password,
        password_confirm: values.confirmPassword,
      };
      const response = await axiosInstance.post(registration, user_data, {
        headers: {
          "Content-type": "application/json",
        },
      });

      if (response.status === 201) {
        dispatch(setLoading({ loading: false }));
        resetForm();
        navigate("/login", {
          state: { from: location },
          replace: true,
        });
      } else {
        dispatch(setLoading({ loading: false }));
        dispatch(setFeedback({ feedback: true }));
      }
    } catch (err) {
      dispatch(setLoading({ loading: false }));
      dispatch(setFeedback({ feedback: true }));
    } finally {
      dispatch(setLoading({ loading: false }));
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },

    validationSchema: validationSchema,
    onSubmit: handleRegistration,
  });

  useEffect(() => {
    
  }, [formik]);

  return (
    <Card className="h-screen mx-auto items-center justify-center w-96 h-85">
      <CardHeader>
        <CardTitle className="text-center font-bold text-gray-800">
          Create an Account
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid gap-1 mt-2 item-center space-y-2">
          <Label htmlFor="email">First name</Label>
          <Input
            id={"firstName"}
            name={"firstName"}
            label="First Name"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
            className={`${formik.errors.firstName ? "border-red-500" : ""}`}
          />
          <span className="text-xs text-red-500 mt-0">
            {formik?.errors?.firstName}
          </span>
        </div>
        <div className="grid gap-1 mt-2 item-center space-y-2">
          <Label htmlFor="last name">Last name</Label>
          <Input
            id={"lastName"}
            name={"lastName"}
            label="Last Name"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
            className={`${formik.errors.lastName ? "border-red-500" : ""}`}
          />
          <span className="text-xs text-red-500 mt-0">
            {formik?.errors?.lastName}
          </span>
        </div>
        <div className="grid gap-1 mt-2 item-center space-y-2">
          <Label htmlFor="email">Email</Label>
          <Input
            id={"f"}
            name={"email"}
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
            className={`${formik.errors.email ? "border-red-500" : ""}`}
          />
          <span className="text-xs text-red-500 mt-0">
            {formik?.errors?.email}
          </span>
        </div>
        <div className="grid gap-1 mt-4 item-center space-y-2">
          <Label htmlFor="password">Password</Label>
          <Input
            id={"password"}
            name={"password"}
            label="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            type="password"
            required
            className={`${formik.errors.password ? "border-red-500" : ""}`}
          />
          <span className="text-xs text-red-500 mt-0">
            {formik?.errors?.password}
          </span>
        </div>
        <div className="grid gap-1 mt-4 item-center space-y-2">
          <Label htmlFor="password">Confirm Password:</Label>
          <Input
            id={"confirmPassword"}
            name={"confirmPassword"}
            label="Confirm password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            type="password"
            required
            className={`${formik.errors.password ? "border-red-500" : ""}`}
          />
          <span className="text-xs text-red-500 mt-0">
            {formik?.errors?.password}
          </span>
        </div>
      </CardContent>
      <CardFooter>
        <Button
          type="submit"
          onClick={() => formik?.handleSubmit()}
          className="w-full"
        >
          Sign Up
        </Button>
      </CardFooter>
      <div className="mb-4">
        <p className="mt-4 text-center text-sm text-gray-500">
          Already have an account?
          <Link
            to="/login"
            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
          >
            Login
          </Link>
        </p>
      </div>
    </Card>
  );
};

export default RegistrationForm;
