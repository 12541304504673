import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Card, CardContent, Typography, Avatar, Box, Divider, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import EmailVerification from '../pages/EmailVerification';
import { useNavigate } from 'react-router-dom';
import AvatarImage from '../assets/images/Avatar.png';


export const ProfileComponent = () => {
  const user = useSelector((state) => state.auth);
  const { email, isVerified, full_name } = user;
  const navigate = useNavigate();
  
  const handleVerifyClick = () => {
    navigate('/verification');
  };  

  return (
    <Container component="main" maxWidth="xs">
      <Card sx={{ maxWidth: 400, marginTop: 18 }}>
        <Box
          sx={{
            height: 140,
            backgroundColor: '#3b86f7',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        />
        <CardContent sx={{ textAlign: 'center', position: 'relative', top: -56 }}>
          <Avatar
            sx={{
              width: 100,
              height: 100,
              border: '4px solid white',
              margin: '0 auto',
            }}
            src={AvatarImage}
            alt="Profile"
          />
          <Typography variant="h6" sx={{ marginTop: 1, fontWeight: 'bold' }}>
            {email}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', marginTop: 1 }}>
            {user.full_name}
          </Typography>
          <Box sx={{ marginTop: 2 }}>
            {isVerified ? (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CheckCircleIcon sx={{ color: 'green', marginRight: 1 }} />
                <Typography variant="body2" color="text.secondary">
                  Verified
                </Typography>
              </Box>
            ) : (
              <Button variant="contained" color="primary" onClick={handleVerifyClick}>
                Verify Now
              </Button>
            )}
          </Box>
        </CardContent>
        {/* <Divider /> */}
        <CardContent sx={{ display: 'flex', justifyContent: 'space-around', paddingY: 2 }}>
          <Box textAlign="center">
            
          </Box>
          
        </CardContent>
      </Card>
    </Container>
  );
}