import { createSlice } from '@reduxjs/toolkit';

const navItemReducer = createSlice({
    name: 'navitem',
    initialState: {
        value: {
            dashboard: false,
            scan: false,
            profile: false,
        }
    },
    reducers: {
        setNavItemActive: (state, action) => {
            state.value = action.payload;
        },
        resetState: (state) => {
            state.value = {
                dashboard:false,
                scan: false,
                profile: false,
            }
        }
    }
});

export const { setNavItemActive, resetState } = navItemReducer.actions;
export default navItemReducer.reducer;