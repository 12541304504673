import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PasswordResetRequestForm from '../components/PasswordResetRequestForm';
import Feedback from '../components/shared/Feedback';
import OTPVerificationForm from '../components/OTPVerificationForm';
import OTPSend from '../components/OTPSend';
import SecureNEX from "../assets/images/secureNEX.png";
import { 
    setFeedback, 
    setOTPSent,
    setError,
    setMessage } 
from '../redux/reducers/passwordResetReducer';

const PasswordResetRequest = () => {
    const isFeedback = useSelector(state => state.password_reset.feedback);

    return (
        <div className="flex h-screen">
            <div className="flex-1 hidden lg:flex bg-zinc-900 justify-center text-white items-center">
                <div>
                    <div className="p-4 bg-white rounded-xl">
                        <img src={SecureNEX} alt="SecureNEX Logo" className="w-32 h-32 relative left-2" />
                    </div>
                    <div className="text-3xl font-semibold text-center mt-2">Secure NEX</div>
                </div>
            </div>
            <div className="flex-1 bg-white flex justify-center items-center">
                <div className="flex flex-col gap-2 w-96">
                    <div className="text-2xl font-semibold text-center mb-4">Password Reset Request</div>
                    {!isFeedback ? (
                        <div className="container p-5 shadow">
                            <PasswordResetRequestForm />
                        </div>
                    ) : (
                        <div className="container p-5 shadow">
                            <Feedback message="Password reset email sent successfully!" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PasswordResetRequest;