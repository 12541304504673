import { createSlice } from '@reduxjs/toolkit';

const scanReducer = createSlice({
    name: 'scan',
    initialState: {
        scan_id: '',
        loading: false,
        feedback: false,
        error: false,
    },
    reducers: {
        setLoading: (state, action) => {
            const { loading } = action.payload
            state.loading = loading
        },
        setFeedback: (state, action) => {
            const { feedback } = action.payload
            state.feedback = feedback
        },
        setError: (state, action) => {
            const { error } = action.payload
            state.error = error
        },
        setScanId: (state, action) => {
            const { scan_id } = action.payload
            state.scan_id = scan_id
        },
    }
});

export const { setLoading, setFeedback, setError, setScanId } = scanReducer.actions;
export default scanReducer.reducer;