import { TableBody } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import { Card, CardContent, Typography, Grid,Box } from "@mui/material";


import {
  Bar,
  BarChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
  Legend,
  LineChart,
  Line,
  CartesianGrid,
  AreaChart, Area,
} from "recharts";
import { axiosInstance } from "../api/axios";
import {
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "../components/ui/table";

// import {
  
// } from "../components/ui/chart";

export const DashboardPage = () => {
  // const items = [
  //   { label: "Critical", value: 0, color: "red" },
  //   { label: "High", value: 0, color: "green" },
  //   { label: "Medium", value: 0, color: "orange" },
  //   { label: "Low", value: 0, color: "yellow" },
  // ];

  const items = [
    { label: "Critical", value: 0, color: "#f17e92" },
    { label: "High", value: 0, color: "#fbd5da" },
    { label: "Medium", value: 0, color: "#bedcfe" },
    { label: "Low", value: 0, color: "#90c7fe" },
    { label: "Info", value: 0, color: "#3b86f7" } // Added Info category
  ];

  const summary = [
    { Type: "Total Scans"},
    { Type: "Average Time for scans"},
    { Type: "Total Domains " },
    
  ];
  const processData = (data) => {
  const result = {};

  // Handle the common fields
  result.timestamp = data["@timestamp"];
  result.userId = data.user_id;
  result.scanId = data.scan_id;
  result.module = data.module;
  result.severity = data.severity;
  result.toolName = data.data.tool_data.tool_name;

  // Handle tool_output fields
  const toolOutput = data.data.tool_data.tool_output;
  result.toolOutput = {};

  if (toolOutput.ASNS) result.toolOutput.ASNS = toolOutput.ASNS;
  if (toolOutput["Interesting Urls"]) result.toolOutput.urls = toolOutput["Interesting Urls"];
  if (toolOutput.IPs) result.toolOutput.IPs = toolOutput.IPs;
  if (toolOutput.Emails) result.toolOutput.Emails = toolOutput.Emails;
  if (toolOutput.Hosts) result.toolOutput.Hosts = toolOutput.Hosts;
  if (toolOutput.subdomains) result.toolOutput.subdomains = toolOutput.subdomains;
  if (toolOutput.port_details) result.toolOutput.portDetails = toolOutput.port_details;
  if (toolOutput.domain_name) result.toolOutput.domainDetails = toolOutput;

  return result;
};

  const data = [
    { name: 'Page A', uv: 100, pv: 2400 },
    { name: 'Page B', uv: 3000, pv: 1398 },
    { name: 'Page C', uv: 2000, pv: 9800 },
    { name: 'Page D', uv: 2780, pv: 3908 },
    { name: 'Page E', uv: 1890, pv: 4800 },
    { name: 'Page F', uv: 2390, pv: 3800 },
    { name: 'Page G', uv: 3490, pv: 4300 },
  ];
  const [fetchError, setFetchError] = useState(false);
  
  const [tableData, setTableData] = useState([]);
  const [summaryData, setSummaryData] = useState({
    TotalScans: 0,
    AverageScanTime: "0m 0s",
    TotalScannedDomains: 0,
  });

  const [totalRowCount, setTotalRowCount] = useState(0);

  const getSummaryData = async () => {
  try {
    // Perform the Axios request
    const { data } = await axiosInstance.get(`/scans/summary`);

    // Update state with the fetched data
    setSummaryData(data);
  } catch (error) {
    // Handle errors gracefully
    console.error('Error fetching summary data:', error);

    // Display an error message to the user
    alert('Failed to fetch summary data. Please try again later.');
  }
};


  useEffect(() => {
    getScanData();
    getSummaryData();
  }, []);



  
  const getRowsCountBySeverity  = (severity, tableData) => {
    return tableData?.filter((row) => row?.severity === severity)?.length ?? 0;
  }

  const chartData = useMemo(() => {
    const data =  {
      critical: getRowsCountBySeverity('critical', tableData),
      high: getRowsCountBySeverity('high', tableData),
      medium: getRowsCountBySeverity('medium', tableData),
      low: getRowsCountBySeverity('low', tableData),
      info: getRowsCountBySeverity('info', tableData)
    }
    return Object.keys(data).map((key) => ({ label: key, value: data[key],color: items.find(item => item.label.toLowerCase() === key)?.color}))
  }, [tableData]);


  const getScanData = async () => {

    try {

      const { data } = await axiosInstance.get(
        `/scans/summary`
      );

      
      const tableRows = data?.data
        ?.map((row) => {
          const toolOutput = row?.data?.tool_data?.tool_output;
          return {
            detection: toolOutput.title,
            severity: row.severity,
            protocol: toolOutput?.protocol,
            endpoint: toolOutput?.endpoint,
            info: toolOutput?.related_info ?? '-'
          }
        })
        .filter(row => row.detection && row.severity); // Exclude records with empty detection or severity

      setTableData([...tableRows]);
      setTotalRowCount(tableRows.length);
      setFetchError(false);

    } catch (error) {
      console.error("Error fetching scan data:", error);
      setFetchError(true);
    }



  };

  if (fetchError) {
    return <div className="text-red-500">Failed to fetch scan data. Please try again later.</div>;
  }

  const barChartData = [...['critical', 'high', 'medium', 'low', 'info'].map((v, ind) => {
    return {
      label: v,
      value: Math.random()*10
    }
  })];

  return (
    <div className="">
      <div className="font-bold text-2xl mb-4">Dashboard</div>
      <div className="border text-xl shadow-md flex justify-between items-center mb-4 px-6 py-2 rounded-md">
        <div>Vulnerabilities Identified</div>
        <div className="font-semibold text-2xl">{totalRowCount}</div>
      </div>
{/* 
      <div className="flex gap-4 mt-4">
        {items.map((item, ind) => {
          return (
            <div
              key={ind}
              className={`p-4 flex-1 cursor-pointer border hover:shadow-md rounded-lg text-2xl text-center hover:bg-${item.color}-100`}
            >
              <div>{item.label}</div>
              <div className={`font-semibold text-3xl text-${item.color}-500`}>
                {item.value}
              </div>

              <table>
                <tr></tr>
              </table>
            </div>
          );
        })}
      </div> */}
      <div className="grid grid-cols-2 gap-4 mt-12">
        <div>
        <Card variant="outlined">
        <ResponsiveContainer width="100%" height={350} >
            <BarChart 
            data={chartData}
            layout="horizontal" // Use 'vertical' if your bars are vertical
            margin={{ top: 30, right: 25, bottom: 0, left: 0 }} // Adjust margins to fit your design

            >
              <XAxis
                dataKey="label"
                stroke="#888888"
                fontSize={12}
                tickLine={false}
                axisLine={false}
              />
              <YAxis
                stroke="#888888"
                fontSize={12}
                tickLine={false}
                axisLine={false}
                tickFormatter={(value) => `${value}`}
              />
              <Bar
                dataKey="value"
                fill="currentColor"
                radius={[4, 4, 0, 0]}
                width={10}
                barSize={55}
                // className="fill-primary"
              />
              {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
            </BarChart>
          </ResponsiveContainer>

        </Card>
          
          
        </div>
        <Card variant="outlined">
        <ResponsiveContainer width="100%" height={350}>

          
              <PieChart>
                  <Pie
                    data={chartData}
                    dataKey="value"
                    // fill="currentColor"
                    isAnimationActive={true}
                    radius={[4, 4, 0, 0]}
                    // cx={500}
                    // cy={200}
                    innerRadius={80}
                    outerRadius={120}
                    // className="fill-primary"
                    // label={({ name, percent, index }) => `${chartData[index].label} ${(percent * 100).toFixed(0)}%`}
                  >
                    {
                      chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))
                    }
                  </Pie>
                  <Tooltip 
                    formatter={(value, name, props) => [`${props.payload.label}`]}
                  />
                  <Legend
                    layout="horizontal"
                    align="center"
                    verticalAlign="bottom"
                    formatter={(value) => (
                      <span style={{ color: 'gray', fontSize: '12px', margin: '0 10px' }}>
                        {value}
                      </span>
                    )}
                    payload={
                      chartData.map((item) => ({
                        id: item.label,
                        type: "square",
                        value: `${item.label}`,
                        color: item.color,
                      }))
                    }
                    wrapperStyle={{
                      display: 'flex',
                      justifyContent: 'center',
                      color: 'black'
                    }}
                  />
                </PieChart>
              </ResponsiveContainer>
        </Card>

        

        
      
        
        <div>
          
        </div>
      </div>

      {/* <ResponsiveContainer width="100%" >
      <div className="flex gap-4 mt-7">
        {summary.map((summary, ind) => {
          return (
            <div
              key={ind}
              className={`p-4 flex-1 cursor-pointer border hover:shadow-md rounded-lg text-2xl text-center hover:bg-${summary.color}-100`}
            >
              <div>{summary.Type}</div>
              <div className={`font-semibold text-3xl text-${summary.color}-500`}>
                {"10"}
              </div>

              <table>
                <tr></tr>
              </table>
            </div>
          );
        })}
      </div>
      </ResponsiveContainer> */}

       

      <Table className="mt-8">
        <TableHeader>
          <TableRow>
            <TableHead className="w-1/3 font-bold">DETECTION</TableHead>
            <TableHead className="font-bold">SEVERITY</TableHead>
            <TableHead className="font-bold">PROTOCOL</TableHead>
            <TableHead className="font-bold">ENDPOINT</TableHead>
            <TableHead className="text-right font-bold">INFO</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {tableData.map((data, ind) => (
            <TableRow key={ind} className="cursor-pointer">
              <TableCell className="truncate max-w-xs">{data.detection}</TableCell>
              <TableCell className="truncate max-w-xs">{data.severity}</TableCell>
              <TableCell className="truncate max-w-xs">{data.protocol}</TableCell>
              <TableCell className="truncate max-w-xs">{data.endpoint}</TableCell>
              <TableCell className="text-right truncate max-w-xs">{data.info}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
