import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom for navigation
import SecureNEX from '../assets/images/secureNEX.png'; // Path to your logo image

const ErrorPage = () => {
    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="bg-white shadow-lg rounded-lg p-6 max-w-sm w-full text-center">
                <img src={SecureNEX} alt="SecureNEX Logo" className="mb-6 mx-auto" style={{ maxWidth: '120px' }} />
                
                <h4 className="text-black text-xl font-bold mb-4">
                    Something went wrong...
                </h4>
                <h6 className="text-gray-700 mb-6">
                    Better Luck Next Time
                </h6>
                
                <Link to="/login" className="block bg-blue-500 text-white py-2 px-4 rounded mb-4">
                    Back to Home
                </Link>
                
                <footer className="text-gray-500 text-xs">
                    &copy; {new Date().getFullYear()} SecureNEX. All rights reserved.
                </footer>
            </div>
        </div>
    );
}

export default ErrorPage;

