import {createSlice} from '@reduxjs/toolkit';

const profileReducer = createSlice({
    name: 'profile',
    initialState: {
        email: null,
        firstName: null,
        lastName: null,
        isVerified: null,
        role: null,
    },
    reducers: {
        setProfile: (state, action) => {
            const { email, first_name, last_name, is_verified } = action.payload
            state.email = email;
            state.firstName = first_name;
            state.lastName = last_name;
            state.isVerified = is_verified;
        },
    }
});

export const { setProfile } = profileReducer.actions;
export default profileReducer.reducer;