import React, { useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { axiosInstance } from "../api/axios";
import { registration } from "../api/auth";
import { setLoading, setFeedback, setError, setMessage } from "../redux/reducers/registrationReducer";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import SecureNEX from "../assets/images/secureNEX.png";

export const SignUpComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.registration.loading);
  const error = useSelector((state) => state.registration.error);
  const message = useSelector((state) => state.registration.message);
  const abortControllerRef = useRef(null);

  const signupValidationSchema = Yup.object({
    firstName: Yup.string().required("Firstname is Required"),
    lastName: Yup.string().required("Lastname is Required"),
    email: Yup.string().email("Invalid email address").required("Email is Required"),
    password: Yup.string().min(8, "Password should be of minimum 8 characters length").required("Password is Required"),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm Password is Required'),
  });

  const handleRegistration = async (values, { setSubmitting, resetForm }) => {
    abortControllerRef.current = new AbortController();
    dispatch(setLoading({ loading: true }));
    dispatch(setError({ error: false }));
    dispatch(setMessage({ message: '' }));

    try {
      const user_data = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        password: values.password,
        password_confirm: values.confirmPassword,
      };
      const response = await axiosInstance.post(registration, user_data, {
        headers: { "Content-type": "application/json" },
      });

      if (response.status === 201) {
        dispatch(setLoading({ loading: false }));
        resetForm();
        navigate("/login", { state: { from: location }, replace: true });
      } else {
        dispatch(setLoading({ loading: false }));
        dispatch(setFeedback({ feedback: true }));
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        if (err.response.data.email) {
          dispatch(setError({ error: true }));
          dispatch(setMessage({ message: "Error while signing up. Please try again." }));
        } else {
          dispatch(setError({ error: true }));
          dispatch(setMessage({ message: "Error while signing up. Please try again." }));
        }
      } else {
        dispatch(setError({ error: true }));
        dispatch(setMessage({ message: "Error while signing up. Please try again." }));
      }
      dispatch(setLoading({ loading: false }));
      dispatch(setFeedback({ feedback: true }));
    } finally {
      dispatch(setLoading({ loading: false }));
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: signupValidationSchema,
    onSubmit: handleRegistration,
  });

  return (
    <div className="flex h-screen">
      <div className="flex-1 hidden lg:flex bg-zinc-900 justify-center text-white items-center">
        <div>
          <div className="p-4 bg-white rounded-xl">
            <img src={SecureNEX} className="w-32 h-32 relative left-2" />
          </div>
          <div className="text-3xl font-semibold text-center mt-2">Secure NEX</div>
        </div>
      </div>
      <div className="bg-white flex-1 flex justify-center items-center">
        <div className="flex flex-col gap-2 w-80">
          <div className="text-2xl text-center font-semibold">Create an account</div>
          <div className="mb-4 text-muted-foreground">Enter your details for creating an account</div>

          {error && <div className="text-sm text-red-500 mb-2">{message}</div>}

          <Label className="mt-2">First Name</Label>
          <Input
            placeholder="John"
            name="firstName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            className={formik.errors.firstName ? "border-red-500" : ""}
          />
          <div className="text-sm text-red-500">{formik.touched.firstName && formik.errors.firstName}</div>

          <Label className="mt-2">Last Name</Label>
          <Input
            placeholder="Doe"
            name="lastName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            className={formik.errors.lastName ? "border-red-500" : ""}
          />
          <div className="text-sm text-red-500">{formik.touched.lastName && formik.errors.lastName}</div>

          <Label className="mt-2">Email</Label>
          <Input
            type="email"
            name="email"
            placeholder="sample@email.com"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            className={formik.errors.email ? "border-red-500" : ""}
          />
          <div className="text-sm text-red-500">{formik.touched.email && formik.errors.email}</div>

          <Label className="mt-2">Password</Label>
          <Input
            type="password"
            name="password"
            placeholder="********"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            className={formik.errors.password ? "border-red-500" : ""}
          />
          <div className="text-sm text-red-500">{formik.touched.password && formik.errors.password}</div>

          <Label className="mt-2">Confirm Password</Label>
          <Input
            type="password"
            name="confirmPassword"
            placeholder="********"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            className={formik.errors.confirmPassword ? "border-red-500" : ""}
          />
          <div className="text-sm text-red-500">{formik.touched.confirmPassword && formik.errors.confirmPassword}</div>

          <Button
            type="submit"
            onClick={formik.handleSubmit}
            className="mt-4"
            disabled={isLoading}
          >
            {isLoading ? "Creating Account..." : "Create Account"}
          </Button>

          <div className="px-8 text-center text-sm text-muted-foreground mt-4">
            Already have an account?{" "}
            <Link to="/login">
              <span className="text-blue-500 cursor-pointer">Login</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
