import { createSlice } from '@reduxjs/toolkit';

const emailVerificationReducer = createSlice({
    name: 'email_verification',
    initialState: {
        loading: false,
        feedback: false,
        error: false,
        message: '',
        otpSent: false,
        verify: false,
        verifyLoading: false,
    },
    reducers: {
        setLoading: (state, action) => {
            const { loading } = action.payload
            state.loading = loading
        },
        setFeedback: (state, action) => {
            const { feedback } = action.payload
            state.feedback = feedback
        },
        setError: (state, action) => {
            const { error } = action.payload
            state.error = error
        },
        setMessage: (state, action) => {
            const { message } = action.payload
            state.message = message
        },
        setOTPSent: (state, action) => {
            const { otp } = action.payload
            state.otp = otp
        },
        setVerify: (state, action) => {
            const { verify } = action.payload
            state.verify = verify
        },
        setVerifyLoading: (state, action) => {
            const { loading } = action.payload
            state.verifyLoading = loading
        },
    }
});

export const { 
    setLoading, 
    setFeedback, 
    setError, 
    setMessage, 
    setOTPSent, 
    setVerify,
    setVerifyLoading } = emailVerificationReducer.actions;
export default emailVerificationReducer.reducer;