import {createSlice} from '@reduxjs/toolkit';

const routeReducer = createSlice({
    name: 'route',
    initialState: {
        value: {
            mainpage: 'home',
            subpage: 'home',
        },
    },
    reducers: {
        routing: (state, action) => {
            state.value = action.payload;
        }, 
    }
});

export const {routing} = routeReducer.actions;
export default routeReducer.reducer;