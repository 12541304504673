import { useEffect } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setAuth,setName, setIsVerified, setUID } from '../../redux/reducers/authReducer';
import { token_verification } from '../../api/auth';
import { axiosInstance } from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const PrivateRoute = ({ children }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const isLoggedIn = useSelector((state) => state.auth.loggedIn);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axiosInstance.get(token_verification);
                
                if (response.status === 200) {
                    dispatch(setAuth({ 
                        loggedIn: true,
                        email: response.data.email,
                    }))
                    dispatch(setIsVerified({
                        isVerified: response.data.is_verified
                    }))
                    dispatch(setUID({ uid: response.data.id }));
                    dispatch(setName({ full_name: response.data.full_name }));
                    navigate(location.pathname, { 
                        state: { from: location }, 
                        replace: true 
                    });
                }
            } catch (err) {
                dispatch(setAuth({ 
                    loggedIn: false,
                    email: '',
                }))
                // navigate('/login', { 
                //     state: { from: location }, 
                //     replace: true 
                // });
            }
        }
        fetchData();
    }, [isLoggedIn]);

    if (!isLoggedIn) {
        return <Navigate to="/login" replace />
    }

    return children;
}

export { PrivateRoute };
