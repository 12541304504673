import { Routes, Route } from "react-router-dom";
// import Login from './pages/Login';
import { Login } from "./new-components/Login";
import { PrivateRoute } from "./components/common/PrivateRoute";
import { Dashboard } from "./new-components/Dashboard";
// import Dashboard from './pages/Dashboard';
import Registration from "./pages/Registration";
import { SignUpComponent } from "./new-components/SignUp";
// import Scan from "./components/dashboard/Scan";
import { ScanComponent as Scan } from "./new-components/ScanCompoent";
import Profile from "./components/dashboard/Profile";
import ErrorPage from "./pages/Error";
import EmailVerification from "./pages/EmailVerification";
import PasswordReset from "./pages/PasswordReset";
import PasswordResetRequest from "./pages/PasswordResetRequest";
import { DashboardPage } from "./new-components/DashboardPage";
import { ProfileComponent } from "./new-components/Profile";

const App = () => {
  return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/registration" element={<SignUpComponent />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route
          path="/verification"
          element={
            <PrivateRoute>
              <EmailVerification />
            </PrivateRoute>
          }
        />
        <Route
          path="/password-reset-request"
          element={<PasswordResetRequest />}
        />
        <Route
          exact
          path="/password-reset/:uid/:token"
          element={<PasswordReset />}
        />
    
        
        <Route path="/" element={
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
        }>
          <Route exact path="dashboard" element={<DashboardPage />} />
          <Route exact path="scan" element={<Scan />} />
          <Route exact path="profile" element={<ProfileComponent />} />
        </Route>
      </Routes>
  );
};

export default App;
