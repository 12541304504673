import { createSlice } from '@reduxjs/toolkit';

const loginReducer = createSlice({
    name: 'login',
    initialState: {
        loading: false,
        feedback: false,
        error: false,
        message: '',
    },
    reducers: {
        setLoading: (state, action) => {
            const { loading } = action.payload
            state.loading = loading
        },
        setFeedback: (state, action) => {
            const { feedback } = action.payload
            state.feedback = feedback
        },
        setError: (state, action) => {
            const { error } = action.payload
            state.error = error
        },
        setMessage: (state, action) => {
            const { message } = action.payload
            state.message = message
        }
    }
});

export const { setLoading, setFeedback, setError, setMessage } = loginReducer.actions;
export default loginReducer.reducer;