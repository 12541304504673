import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useRef, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ClipLoader } from 'react-spinners';
import { axiosUnAuth } from '../api/axios';
import { password_reset } from '../api/auth';
import Feedback from '../components/shared/Feedback';
import SecureNEX from "../assets/images/secureNEX.png";
import { passwordResetValidationSchema } from '../helpers/passwordResetValidationSchema';
import { setLoading, setFeedback, setError } from '../redux/reducers/passwordResetReducer';

const PasswordReset = () => {
    const isFeedback = useSelector(state => state.password_reset.feedback);
    const isLoading = useSelector(state => state.password_reset.loading);
    const dispatch = useDispatch();
    const { uid, token } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const abortControllerRef = useRef(null);

    const initialValues = {
        password: '',
        confirmPassword: '',
    };

    const handlePasswordReset = async (values, { setSubmitting, resetForm }) => {
        abortControllerRef.current = new AbortController();
        dispatch(setLoading({ loading: true }));

        

        try {
            const response = await axiosUnAuth.post(password_reset, {
                password: values.password,
                uid: uid,
                token: token
            }, {
                headers: {
                    'Content-type': 'application/json',
                }
            });

           

            if (response.status === 200) {
                dispatch(setLoading({ loading: false }));
                resetForm();
                dispatch(setFeedback({ feedback: true }));
            } else {
                console.error('Error resetting password', response);
                navigate('/error', {
                    state: { from: location },
                    replace: true
                });
                dispatch(setError({ error: true }));
            }
        } catch (err) {
            console.error('Error resetting password', err);
            navigate('/error', {
                state: { from: location },
                replace: true
            });
            dispatch(setError({ error: true }));
        } finally {
            dispatch(setLoading({ loading: false }));
            setSubmitting(false);
        }
    };

    useEffect(() => {
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, []);

    return (
        <div className="flex h-screen">
            <div className="flex-1 hidden lg:flex bg-zinc-900 justify-center text-white items-center">
                <div>
                    <div className="p-4 bg-white rounded-xl">
                        <img src={SecureNEX} alt="SecureNEX Logo" className="w-32 h-32 relative left-2" />
                    </div>
                    <div className="text-3xl font-semibold text-center mt-2">Secure NEX</div>
                </div>
            </div>
            <div className="flex-1 bg-white flex justify-center items-center">
                <div className="flex flex-col gap-2 w-96">
                    <div className="text-2xl font-semibold text-center mb-4">Password Reset</div>
                    {!isFeedback ? (
                        <div className="container p-5 shadow">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={passwordResetValidationSchema}
                                onSubmit={handlePasswordReset}
                            >
                                {({ isSubmitting }) => (
                                    <Form>
                                        <div className="mt-3">
                                            <label
                                                htmlFor="password"
                                                className="form-label block mb-1"
                                                style={{ color: 'black', fontWeight: 'normal' }}
                                            >
                                                New Password
                                            </label>
                                            <Field
                                                type="password"
                                                id="password"
                                                name="password"
                                                className="form-control bg-white border border-black rounded p-2 w-full text-black"
                                                style={{ backgroundColor: 'white', color: 'black' }}
                                            />
                                            <ErrorMessage
                                                name="password"
                                                component="div"
                                                className="text-red-500 text-xs mt-1 leading-tight"
                                            />
                                        </div>

                                        <div className="mt-3">
                                            <label
                                                htmlFor="confirmPassword"
                                                className="form-label block mb-1"
                                                style={{ color: 'black', fontWeight: 'normal' }}
                                            >
                                                Confirm New Password
                                            </label>
                                            <Field
                                                type="password"
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                className="form-control bg-white border border-black rounded p-2 w-full text-black"
                                                style={{ backgroundColor: 'white', color: 'black' }}
                                            />
                                            <ErrorMessage
                                                name="confirmPassword"
                                                component="div"
                                                className="text-red-500 text-xs mt-1 leading-tight"
                                            />
                                        </div>

                                        <button
                                            type="submit"
                                            className="bg-black text-white w-full mt-4 p-2 rounded"
                                            disabled={isSubmitting || isLoading}
                                        >
                                            {isLoading ? (
                                                <ClipLoader size={21} color={"#fff"} />
                                            ) : (
                                                'Change password'
                                            )}
                                        </button>
                                    </Form>
                                )}
                            </Formik>





                        </div>
                    ) : (
                        <div className="container p-5 shadow">
                            <Feedback message="Password was changed successfully!" />
                            <div className="mt-4 w-100">
                                <h6 className="text-center pb-2 w-100">Login to the Account</h6>
                                <Link to="/login" className="btn btn-secondary btn-outline-primary text-light w-100">
                                    <span className="text-light">Login</span>
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PasswordReset;
