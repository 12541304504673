import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { axiosInstance } from "../api/axios";
import SecureNEX from "../assets/images/secureNEX.png";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { useToast } from "../components/ui/use-toast";
import { setAuth, setIsVerified, setUID } from "../redux/reducers/authReducer";
import { setFeedback, setLoading, setMessage } from "../redux/reducers/loginReducer";

export const Login = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.login.loading);
  const isFeedback = useSelector((state) => state.login.feedback);
  const message = useSelector((state) => state.login.message);

  const loginValidationSchema = Yup.object({
    email: Yup.string().required("Email is Required"),
    password: Yup.string().required("Password is Required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: (form) => {
      handleLogin(form);
    },
  });

  const handleLogin = async ({ email, password }) => {
    dispatch(setLoading({ loading: true }));
    try {
      const response = await axiosInstance.post(
        "/users/login",
        { email, password },
        { headers: { "Content-type": "application/json" } }
      );

      if (response.status === 200) {
        if (response.data.is_verified === true) {
          dispatch(setAuth({ loggedIn: true, email: response.data.email }));
          dispatch(setIsVerified({ isVerified: response.data.is_verified }));
          dispatch(setUID({ uid: response.data.id }));
          dispatch(setLoading({ loading: false }));
          formik.resetForm();
          navigate("/dashboard", { state: { from: location }, replace: true });
        } else {
          dispatch(setAuth({ loggedIn: false, email: response.data.email }));
          dispatch(setIsVerified({ isVerified: response.data.is_verified }));
          dispatch(setUID({ uid: response.data.id }));
          dispatch(setLoading({ loading: false }));
          formik.resetForm();
          navigate("/verification", { state: { from: location }, replace: true });
        }
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(setFeedback({ feedback: true }));
        dispatch(setMessage({ message: err.response.data.detail }));
      } else {
        toast({
          variant: "destructive",
          title: "Sorry, cannot login",
          description: "Getting error when trying to login with these credentials",
        });
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  return (
    <div className="flex h-screen">
      <div className="flex-1 hidden lg:flex bg-zinc-900 flex justify-center text-white items-center">
        <div>
          <div className="p-4 bg-white rounded-xl">
            <img src={SecureNEX} className="w-32 h-32 relative left-2" />
          </div>
          <div className="text-3xl font-semibold text-center mt-2">
            Secure NEX
          </div>
        </div>
      </div>
      <div className="flex-1 bg-white flex  justify-center items-center">
        <div className="flex flex-col gap-2 w-72">
          <div className="text-2xl font-semibold text-center">Login</div>
          <div className="mb-4 text-muted-foreground">
            Enter your Email & Password for Login
          </div>
          <Label>Email</Label>
          <Input
            name="email"
            className={`${formik.errors.email ? "border-2 border-red-400" : ""}`}
            onChange={formik.handleChange}
            placeholder="name@example.com"
          />
          <div className="text-sm text-red-500 mt-0">{formik.errors.email}</div>
          <Label className="mt-2">Password</Label>
          <Input
            name="password"
            className={`${formik.errors.password ? "border-2 border-red-400" : ""}`}
            onChange={formik.handleChange}
            type="password"
            placeholder="12345678"
          />
          <div className="text-sm text-red-500 mt-0">{formik.errors.password}</div>
          <Button type="submit" className="mt-2" onClick={formik.handleSubmit} disabled={isLoading}>
            {isLoading ? "Logging in..." : "Login"}
          </Button>

          {isFeedback && (
            <div className="text-sm text-red-500 mt-2 text-center">
              {message}
            </div>
          )}

          <div className="px-8 text-center text-sm text-muted-foreground mt-4">
            Don't have an account?
            <Link to="/registration">
              <span className="text-blue-500 cursor-pointer"> Sign Up</span>
            </Link>
          </div>
          <div className="px-8 text-center text-sm text-muted-foreground mt-4">
            <Link to="/password-reset-request">
              <span className="text-blue-500 cursor-pointer">
                Forgot Password?
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
